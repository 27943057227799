import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  forwardRef,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import "../../defaultCss/styles.css";
import "../../defaultCss/css-agent-plugin.css";
import { fetchProviderRequest } from "../../reducers/reducer-get-quote";
import { fetchCommonRequest } from "../../reducers/reducer-common";
import {
  initialData,
  updateData,
  applyRequest,
  getAgentRequest,
} from "../../reducers/reducer-apply-quote";
import {
  getDays,
  getYears,
  getObjectToQueryParams,
  getAge,
  convertTitleToGender,
  navigateToGetAQuote,
} from "../../utils/utils";
import {
  PROVIDER_ID,
  MONTH_LIST,
  GENDER_LIST,
  ALLIANZ_GENDER_LIST,
  TITLE_LIST,
  VALIDATION,
  PAGE_URL,
  DATE_PICKER_FORMAT_DATE,
} from "../../constants/constant";
import { PaymentStepsBreadcrumbItems } from "../../enums/enum";
import Upload from "../Upload";
import Spinner from "../Spinner";
import useLocationAnnouncement from "../../hooks/useLocationAnnouncement";
import CustomModal from "../CustomModal";
import PaymentStepsBreadcrumb from "../PaymentStepsBreadcrumb";
import PolicySummary from "../PolicySummary";
import ErrorModal from "../ErrorModal";
import useModal from "../../hooks/useModal";
import { parseDateToStringDisplay } from "../../utils/utils";
import { Collapse } from "../Collapse";
import produce from "immer";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import MaskedInput from "react-text-mask";
import { Tooltip } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";

const ApplyAQuote = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    clearErrors,
  } = useForm();

  const captchaRef = useRef(null);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const providerList = useSelector(
    (store) => store.getAQuoteReducer.providerList
  );
  const { nationality, institution, paymentCountry } = useSelector(
    (store) => store.getCommonReducer
  );
  const years = getYears();

  const data = useSelector((store) => store.applyQuoteReducer.data);

  const student = useSelector((store) => store.applyQuoteReducer.student);

  const [selectedNationality, setSelectedNationality] = useState(
    data.nationality
  );

  const [selectedIsCurrentlyInAus, setSelectedIsCurrentlyInAus] = useState(
    data.isCurrentInAus
  );

  const [selectedProvider, setSelectedProvider] = useState({});
  const [initialBackTransaction, setInitialBackTransaction] = useState(true);

  const { data: locationAnnouncementData, isLoadingLocationAnnouncementData } =
    useLocationAnnouncement({ location: selectedNationality });
  const {
    isShowing: isShowingLocationAnnouncementModal,
    toggle: toggleLocationAnnouncementModal,
  } = useModal(
    !isLoadingLocationAnnouncementData && locationAnnouncementData
      ? {
        isShowing: locationAnnouncementData !== "",
      }
      : undefined
  );

  const { isShowing: isShowingConfirmModal, toggle: toggleConfirmModal } =
    useModal();

  // const [isError, setIsError] = useState(false);
  const { isShowing: isShowingErrorModal, toggle: toggleErrorModal } =
    useModal();

  const { isShowing: isShowingApplyAgainodal, toggle: toggleApplyAgainModal } =
    useModal();

  const { isShowing: isShowingDateErrorModal, toggle: toggleDateErrorModal } =
    useModal();

  const isProcessing = useSelector(
    (store) => store.applyQuoteReducer.isProcessing
  );

  const searchParams = useLocation().search;
  const providerNameParam = new URLSearchParams(searchParams).get(
    "providerName"
  );
  const startDateParam = new URLSearchParams(searchParams).get("startDate");
  const endDateParam = new URLSearchParams(searchParams).get("endDate");
  const adultsNumParam = new URLSearchParams(searchParams).get("adultsNum");
  const childrensNumParam = new URLSearchParams(searchParams).get(
    "childrensNum"
  );
  const typeParam = new URLSearchParams(searchParams).get("type");

  const [searchParamsUrl] = useSearchParams();
  const agent = useMemo(
    () => new URLSearchParams(searchParamsUrl).get("agentcode"),
    [searchParamsUrl]
  );
  const agentname = useMemo(
    () => new URLSearchParams(searchParamsUrl).get("agentname"),
    [searchParamsUrl]
  );
  const startDateInputRef = useRef(null);
  const endDateInputRef = useRef(null);

  function determineCoverType() {
    const nOfAdults = Number(adultsNumParam);
    const nOfChildren = Number(childrensNumParam);
    if (nOfAdults === 2) {
      return nOfChildren === 0 ? "Couple" : "Family";
    } else if (nOfAdults === 1) {
      return nOfChildren === 1 ? "Single parent" : "Single";
    }
    return "Unknown group";
  }

  const dateOfBirthValidation = () => {
    clearErrors("birthday");
    const currentDate = new Date();
    const { day, month, year } = getValues("birthday");
    const selectedDob = new Date(year, month - 1, day);
    const age = currentDate.getFullYear() - selectedDob.getFullYear();
    const monthDifference = currentDate.getMonth() - selectedDob.getMonth();
    const dayDifference = currentDate.getDate() - selectedDob.getDate();
    if (determineCoverType() === "Single") {
      if (
        age > 10 ||
        (age === 10 &&
          (monthDifference > 0 ||
            (monthDifference === 0 && dayDifference >= 0)))
      ) {
        return true;
      }
      return "The main insured person must be at least 10 years old.";
    }
    return true;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    let selected = {};
    if (
      typeParam === "back-to-transaction" &&
      student != null &&
      student.id &&
      initialBackTransaction
    ) {
      selected = providerList.find(
        (pro) => pro.providerId === student.providerId
      );

      setInitialBackTransaction(false);

      setSelectedProvider(selected);

      toggleApplyAgainModal();

      getProvider(selected, student, true);
    } else {
      const start = new Date(startDateParam);
      const end = new Date(endDateParam);
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      if (start < now || end < now) {
        toggleDateErrorModal();
      }

      selected =
        providerNameParam != null
          ? providerList.find((pro) => pro.providerName === providerNameParam)
          : null;

      setSelectedProvider(selected);

      getProvider(selected, data, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //get latest price + logoImg
  const getProvider = (selected, currentData, isBackToTransaction) => {
    if (selected == null) {
      navigateToGetAQuote(navigate, agent, agentname);
      return;
    }

    const _agent = isBackToTransaction ? currentData.agentCode : agent;
    const _agentname = isBackToTransaction
      ? currentData.educationAgent
      : agentname;

    let request = {
      quoteCommonData: {
        startDate: isBackToTransaction ? currentData.startDate : startDateParam,
        endDate: isBackToTransaction ? currentData.endDate : endDateParam,
        adultsNum: isBackToTransaction
          ? currentData.numberOfAdults
          : adultsNumParam,
        childrensNum: isBackToTransaction
          ? currentData.numberOfChildren
          : childrensNumParam,
      },
      providerId: selected?.providerId,
      agent: _agent,
      isBackToTransaction: isBackToTransaction,
    };
    dispatch(initialData(request));
    dispatch(fetchProviderRequest(request));
    dispatch(
      fetchCommonRequest({
        providerId: selected?.providerId,
        agent: _agent,
      })
    );

    if (_agent) {
      dispatch(getAgentRequest({ agent: _agent, agentname: _agentname }));
    }
  };

  const onValueChange = useCallback(
    (propertyName, type = "string", controlName = "") =>
      (event) => {
        const { value, checked } = event.target;

        let formatValue =
          event.target.type === "checkbox"
            ? propertyName === "hasAustralianAddress"
              ? (!checked).toString()
              : checked.toString()
            : value;

        formatValue =
          propertyName.includes("firstName") ||
            propertyName.includes("lastName")
            ? formatValue.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
            : formatValue;

        switch (type) {
          case "number":
            formatValue = parseInt(formatValue);
            break;
          case "boolean":
            formatValue = formatValue === "true";
            break;
          default:
            break;
        }
        dispatch(
          updateData({
            propertyName: propertyName,
            value: formatValue,
            type: type,
          })
        );
        setValue(controlName ? controlName : propertyName, formatValue);
        trigger(controlName ? controlName : propertyName);

        if (propertyName === "nationality") {
          setSelectedNationality(formatValue);
        }

        if (propertyName.includes("title")) {
          if (propertyName.includes("children")) {
            const index = propertyName.split("children[")[1][0];
            onValueChange(
              `children[${index}].gender`,
              "number",
              `children_${index}_gender`
            )({
              target: {
                value: convertTitleToGender(formatValue),
              },
            });
          } else if (propertyName.includes("partner")) {
            const index = propertyName.split("partner[")[1][0];
            onValueChange(
              `partner[${index}].gender`,
              "number",
              `partner_${index}_gender`
            )({
              target: {
                value: convertTitleToGender(formatValue),
              },
            });
          } else {
            onValueChange(
              "gender",
              "number"
            )({
              target: {
                value: convertTitleToGender(formatValue),
              },
            });
          }
        }
      },
    [dispatch, setValue, trigger]
  );

  const onFilesChanged = (files, propertyName) => {
    setValue(propertyName, files);
    dispatch(
      updateData({
        propertyName: propertyName,
        value: files,
        type: "array",
      })
    );
  };

  const userInputData = useMemo(() => {
    let request = {
      id: data.id,
      providerId: data.providerId,
      startDate: data.startDate,
      endDate: data.endDate,
      numberOfAdults: data.numberOfAdults,
      numberOfChildren: data.numberOfChildren,
      title: data.title,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      birthday: new Date(
        `${data.birthday.year}-${data.birthday.month
          .toString()
          .padStart(2, "0")}-${data.birthday.day.toString().padStart(2, "0")}`
      ),
      passport: data.passport,
      nationality: data.nationality,
      isCurrentInAus: data.isCurrentInAus,
      paymentCountry: data.paymentCountry,
      institution: data.institution,
      studentId: data.studentId,
      existingMember: data.existingMember,
      policyNumber: data.policyNumber,
      streetAndress: data.streetAndress,
      city: data.city,
      state: data.state,
      postCode: data.postCode,
      phone: data.phone,
      email: data.email,
      emailConfirmation: data.emailConfirmation,
      emailReceiveInvoice: data.emailReceiveInvoice,
      educationAgent: data.educationAgent,
      hasAustralianAddress: data.hasAustralianAddress,
      agentCode: data.agentCode,
      persons: [],
    };

    data.partner.forEach((item) => {
      request.persons.push({
        ...item,
        birthday: new Date(
          `${item.birthday.year}-${item.birthday.month
            .toString()
            .padStart(2, "0")}-${item.birthday.day.toString().padStart(2, "0")}`
        ),
      });
    });

    data.children.forEach((item) => {
      request.persons.push({
        ...item,
        birthday: new Date(
          `${item.birthday.year}-${item.birthday.month
            .toString()
            .padStart(2, "0")}-${item.birthday.day.toString().padStart(2, "0")}`
        ),
      });
    });

    request.passportFiles = [];
    request.offerLetterOrCOEFiles = [];
    request.birthCertificateFiles = [];
    request.coiFiles = [];
    request.identificationOfMotherOrFatherFiles = [];

    data.passportFiles.forEach((file) => {
      request.passportFiles.push(file.newFileName);
    });

    data.offerLetterOrCOEFiles.forEach((file) => {
      request.offerLetterOrCOEFiles.push(file.newFileName);
    });

    data.birthCertificateFiles.forEach((file) => {
      request.birthCertificateFiles.push(file.newFileName);
    });

    data.coiFiles.forEach((file) => {
      request.coiFiles.push(file.newFileName);
    });

    data.identificationOfMotherOrFatherFiles.forEach((file) => {
      request.identificationOfMotherOrFatherFiles.push(file.newFileName);
    });

    return request;
  }, [
    data.id,
    data.agentCode,
    data.birthday.day,
    data.birthday.month,
    data.birthday.year,
    data.children,
    data.city,
    data.educationAgent,
    data.email,
    data.emailConfirmation,
    data.emailReceiveInvoice,
    data.paymentCountry,
    data.endDate,
    data.existingMember,
    data.passportFiles,
    data.offerLetterOrCOEFiles,
    data.birthCertificateFiles,
    data.coiFiles,
    data.identificationOfMotherOrFatherFiles,
    data.firstName,
    data.gender,
    data.hasAustralianAddress,
    data.institution,
    data.lastName,
    data.isCurrentInAus,
    data.nationality,
    data.numberOfAdults,
    data.numberOfChildren,
    data.partner,
    data.passport,
    data.phone,
    data.policyNumber,
    data.postCode,
    data.providerId,
    data.startDate,
    data.state,
    data.streetAndress,
    data.studentId,
    data.title,
  ]);

  const onConfirm = () => {
    toggleConfirmModal();
  };

  const onSubmit = () => {
    toggleConfirmModal();
    dispatch(
      applyRequest({
        data: userInputData,
        callbackApplySuccess: () => {
          const path = PAGE_URL.OSHC_AU_PAYMENT_A_QUOTE;
          const options = {
            agentcode: agent || undefined,
            agentname: agentname || undefined,
          };
          const params = getObjectToQueryParams(options);
          const pathWithParams = options ? `${path}?${params}` : path;
          navigate(pathWithParams);
        },
        callbackApplyError: () => {
          toggleErrorModal();
        },
      })
    );
  };

  const onApplyAgain = () => {
    getProvider(selectedProvider, data, true);
    toggleApplyAgainModal();
  };

  const onBack = () => {
    navigateToGetAQuote(navigate, agent, agentname);
  };

  const days = useMemo(
    () =>
      getDays(parseInt(data.birthday.month), parseInt(data.birthday.year)) ||
      [],

    [data.birthday.month, data.birthday.year]
  );

  const confirmData = useMemo(() => {
    const title = TITLE_LIST.find(
      (t) => t.value === userInputData.title
    )?.label;

    const gender = (
      data.providerId === PROVIDER_ID.ALLIANZ
        ? ALLIANZ_GENDER_LIST
        : GENDER_LIST
    ).find((t) => t.value === userInputData.gender)?.label;

    const userNationality = nationality.find(
      (t) => t.code === userInputData.nationality
    )?.name;

    const userInstitution =
      institution?.find((t) => t.code === userInputData.institution)?.name ||
      undefined;

    const selectedProvider = providerList.find(
      (pro) => pro.providerId === data.providerId
    );

    const filterConfirmData = {
      "Start Date": parseDateToStringDisplay(userInputData.startDate),
      "End Date": parseDateToStringDisplay(userInputData.endDate),
      "No of Adults": userInputData.numberOfAdults,
      "No of children": userInputData.numberOfChildren,
      title: title,
      name: `${userInputData.firstName} ${userInputData.lastName}`,
      gender: gender,
      birthday: parseDateToStringDisplay(userInputData.birthday),
      passport: userInputData.passport,
      nationality: userNationality,
      institution: userInstitution,
      email: userInputData.email,
      phone: userInputData.phone,
      price: selectedProvider != null ? selectedProvider.price.toFixed(2) : "",
    };

    const content = React.createElement(
      "div",
      { className: "confirmModal" },
      <div>
        {Object.entries(filterConfirmData).map((data) => {
          const [key, value] = data;
          return value != null && value !== "" ? (
            <div key={key} className="item">
              <span className="title">{key}: </span>
              <span className="content">{value}</span>
            </div>
          ) : undefined;
        })}
      </div>
    );

    return content;
  }, [data.providerId, institution, nationality, userInputData]);

  const applySuccessData = useMemo(() => {
    const content = React.createElement(
      "div",
      { className: "confirmModal" },
      <div>
        Your application was successful. We sent the payment link to your email.
        Please check your email and pay your insurance.
      </div>
    );

    return content;
  }, []);

  const errorData = useMemo(() => {
    return (
      <div>
        An error occurred:
        <ul>
          <li>
            Your information is invalid. Please check your information
            (passport, phone...) again.
          </li>
          <li>Your information has already existed on system.</li>
          <li>The quotation does not match.</li>
        </ul>
        Please contact us at info@oshcstudents.com for support!
      </div>
    );
  }, []);

  const handleExpand = (index) => {
    const expanedIndexs = produce(data.expanedIndexs, (draftData) => {
      draftData[index] = !draftData[index];
    });

    dispatch(
      updateData({
        propertyName: "expanedIndexs",
        value: expanedIndexs,
        type: "array",
      })
    );
  };

  const CustomDateInput = forwardRef(({ value, onClick, onChange }, ref) => {
    const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
    return (
      <MaskedInput
        ref={ref}
        mask={dateMask}
        guide={false}
        placeholderChar={"\u2000"}
        value={value}
        onChange={onChange}
        onClick={onClick}
      />
    );
  });

  const handleChangeCommonQuoteData = useCallback(
    (name, e) => {
      let updatedValue = e;
      if (name === "numberOfAdults" || name === "numberOfChildren") {
        updatedValue = parseInt(e.target.value);
      } else {
        updatedValue = moment(new Date(e));
      }
      dispatch(
        updateData({
          propertyName: name,
          value: updatedValue,
        })
      );
    },
    [dispatch]
  );

  return (
    <div className="page">
      <div id="template-oshc" className="uc">
        <div id="service-suport" className="spec">
          <h3 className="section-title">Register with our services</h3>
          <div id="wrap" className="template-oshc">
            <div className="fix-width">
              <PaymentStepsBreadcrumb
                activeField={PaymentStepsBreadcrumbItems.ApplyAQuote}
              />
              <div className="main-apply">
                <PolicySummary data={data} />

                <div className="form-of-oshc">
                  <form onSubmit={handleSubmit(onConfirm)}>
                    <br />
                    <h4 className="form-title">Personal Details</h4>
                    <div className="personal">
                      <div className="list-field">
                        <div className="field-item title ">
                          <div className="wrap-field">
                            <label htmlFor="title-mr">Title</label>
                            <div className="wrap-input-field">
                              <select
                                id="title-mr"
                                name="personal[title]"
                                value={data.title}
                                onChange={onValueChange("title", "number")}
                              >
                                {TITLE_LIST.map((x) => (
                                  <option key={x.value} value={x.value}>
                                    {x.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="field-item name">
                          <div className="wrap-field">
                            <label htmlFor="first_name">
                              Name<span className="text-required">*</span>
                            </label>
                            <div className="wrap-input-field">
                              <span className="first-name">
                                <input
                                  id="first_name"
                                  name="personal[first_name]"
                                  type="text"
                                  value={data.firstName}
                                  maxLength={24}
                                  {...register("firstName", {
                                    required: VALIDATION.messages.required,
                                    pattern: {
                                      value: (data.providerId === PROVIDER_ID.AHM || data.providerId === PROVIDER_ID.MEDIBANK) ? /^(?![_\-\.,;]+$)(?!.*(?:^|[\s])[_\-\.,;](?:[\s]|$))^[A-Za-z\d](?:[A-Za-z\d\s_\-\.,;]*[A-Za-z\d])?$/ : /^[A-Za-z\d_-\s]+$/,
                                      message:
                                        VALIDATION.messages.englishRequired,
                                    },
                                  })}
                                  onChange={onValueChange("firstName")}
                                  placeholder="First Name"
                                />
                                {errors.firstName && (
                                  <span className="err-item">
                                    {errors.firstName.message}
                                  </span>
                                )}
                              </span>
                              <span className="last-name">
                                <input
                                  id="last_name"
                                  name="personal[last_name]"
                                  type="text"
                                  value={data.lastName}
                                  {...register("lastName", {
                                    required: VALIDATION.messages.required,
                                    pattern: {
                                      value: (data.providerId === PROVIDER_ID.AHM || data.providerId === PROVIDER_ID.MEDIBANK) ? /^(?![_\-\.,;]+$)(?!.*(?:^|[\s])[_\-\.,;](?:[\s]|$))^[A-Za-z\d](?:[A-Za-z\d\s_\-\.,;]*[A-Za-z\d])?$/ : /^[A-Za-z\d_-\s]+$/,
                                      message:
                                        VALIDATION.messages.englishRequired,
                                    },
                                  })}
                                  onChange={onValueChange("lastName")}
                                  placeholder="Last Name"
                                />
                                {errors.lastName && (
                                  <span className="err-item">
                                    {errors.lastName.message}
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="field-item gender">
                          <div className="wrap-field">
                            <label htmlFor="gender">
                              Gender<span className="text-required">*</span>
                            </label>
                            <div className="wrap-input-field">
                              <select
                                id="gender"
                                name="personal[gender]"
                                value={data.gender}
                                onChange={onValueChange("gender", "number")}
                              >
                                {(data.providerId === PROVIDER_ID.ALLIANZ
                                  ? ALLIANZ_GENDER_LIST
                                  : GENDER_LIST
                                ).map((x) => (
                                  <option key={x.value} value={x.value}>
                                    {x.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="field-item birthday">
                          <div className="wrap-field">
                            <label htmlFor="birth-day">
                              Date of birth
                              <span className="text-required">*</span>
                            </label>
                            <div className="wrap-input-field field-val">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <span className="date-item">
                                  <select
                                    id=""
                                    name="personal[birth][day]"
                                    value={data.birthday.day}
                                    {...register("birthday.day", {
                                      validate: {
                                        ageRequirement: dateOfBirthValidation,
                                      },
                                    })}
                                    onChange={onValueChange(
                                      "birthday.day",
                                      "number"
                                    )}
                                  >
                                    {days.map((day) => (
                                      <option key={day} value={day}>
                                        {day}
                                      </option>
                                    ))}
                                  </select>
                                </span>
                                <span className="date-item">
                                  <select
                                    id=""
                                    name="personal[birth][month]"
                                    value={data.birthday.month}
                                    {...register("birthday.month", {
                                      validate: {
                                        ageRequirement: dateOfBirthValidation,
                                      },
                                    })}
                                    onChange={onValueChange(
                                      "birthday.month",
                                      "number"
                                    )}
                                  >
                                    {MONTH_LIST.map((month) => (
                                      <option
                                        key={month.value}
                                        value={month.value}
                                      >
                                        {month.label}
                                      </option>
                                    ))}
                                  </select>
                                </span>
                                <span className="date-item">
                                  <select
                                    name="personal[birth][year]"
                                    value={data.birthday.year}
                                    {...register("birthday.year", {
                                      validate: {
                                        ageRequirement: dateOfBirthValidation,
                                      },
                                    })}
                                    onChange={onValueChange(
                                      "birthday.year",
                                      "number"
                                    )}
                                  >
                                    {years.map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                </span>
                              </div>
                              {errors.birthday?.day && (
                                <span className="err-item">
                                  {errors.birthday.day.message}
                                </span>
                              )}
                              {errors.birthday?.month && (
                                <span className="err-item">
                                  {errors.birthday.month.message}
                                </span>
                              )}
                              {errors.birthday?.year && (
                                <span className="err-item">
                                  {errors.birthday.year.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="field-item passport">
                          <div className="wrap-field">
                            <label htmlFor="passport">
                              Passport number
                              <span className="text-required">*</span>
                            </label>
                            <div className="wrap-input-field">
                              <input
                                type="text"
                                id="passport"
                                name="personal[passport]"
                                value={data.passport}
                                {...register("passport", {
                                  required: VALIDATION.messages.required,
                                })}
                                onChange={onValueChange("passport")}
                                maxLength={10}
                              />
                              {errors.passport && (
                                <span className="err-item">
                                  {errors.passport.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="field-item country ">
                          <div className="wrap-field">
                            <label htmlFor="country">
                              Nationality
                              <span className="required-address text-required">
                                *
                              </span>
                            </label>
                            <div className="wrap-input-field">
                              <select
                                className="select-country"
                                id="country"
                                name="personal[country]"
                                value={data?.nationality}
                                {...register("nationality", {
                                  validate: {
                                    required: (_) => {
                                      const { nationality } = getValues();
                                      if (!nationality)
                                        return VALIDATION.messages.required;
                                      return true;
                                    },
                                  },
                                })}
                                onChange={onValueChange("nationality")}
                              >
                                {nationality.map((x) => (
                                  <option key={x.code} value={x.code}>
                                    {x.name}
                                  </option>
                                ))}
                              </select>
                              {errors.nationality && (
                                <span className="err-item">
                                  {errors.nationality.message}
                                </span>
                              )}
                            </div>
                            {selectedProvider?.providerId &&
                              selectedProvider?.providerId ===
                              PROVIDER_ID.BUPA && (
                                <div className="field-item country ">
                                  <div className="wrap-field">
                                    <label htmlFor="isCurrentInAus">
                                      Is student currently in Australia?
                                      <span className="required-address text-required">
                                        *
                                      </span>
                                    </label>
                                    <div className="wrap-input-field">
                                      <select
                                        className="select-is-current-in-aus"
                                        id="isCurrentInAus"
                                        name="personal[isCurrentInAus]"
                                        value={data?.isCurrentInAus}
                                        {...register("isCurrentInAus", {
                                          validate: {
                                            required: (_) => {
                                              const { isCurrentInAus } = getValues();
                                              if (!isCurrentInAus || isCurrentInAus == "")
                                                return VALIDATION.messages.required;
                                              return true;
                                            },
                                          },
                                        })}
                                        onChange={onValueChange("isCurrentInAus")}
                                      >
                                        <option value="">Please select</option>
                                        <option value="True">Yes</option>
                                        <option value="False">No</option>
                                      </select>
                                      {errors.isCurrentInAus && (
                                        <span className="err-item">
                                          {errors.isCurrentInAus.message}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>)
                            }
                          </div>
                        </div>
                        {selectedProvider?.providerId &&
                          selectedProvider?.providerId ===
                          PROVIDER_ID.ALLIANZ && (
                            <div className="field-item policy_select ">
                              <div className="wrap-field">
                                <label htmlFor="institution_select">
                                  Where is the student studying (institution)
                                  <span className="text-required">*</span>
                                  <svg
                                    id="institution-tooltip"
                                    className="institution-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    data-slot="icon"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                    />
                                  </svg>
                                  <Tooltip
                                    placement="top"
                                    isOpen={tooltipOpen}
                                    target="institution-tooltip"
                                    toggle={toggle}
                                  >
                                    <span className="institution-tooltip-text">
                                      If your education institution is not
                                      available on this list, please choose one
                                      of options: Other - ACT, Other - NSW,
                                      Other - NT, Other - QLD, Other - SA, Other
                                      - TAS, Other - VIC, Other - WA, Other
                                      Australia.
                                    </span>
                                  </Tooltip>
                                </label>
                                <div className="wrap-input-field">
                                  <select
                                    id="institution_select"
                                    name="personal[institution_select]"
                                    value={data?.institution}
                                    {...register("institution", {
                                      validate: {
                                        required: (_) => {
                                          const { institution } = getValues();
                                          if (!institution)
                                            return VALIDATION.messages.required;
                                          return true;
                                        },
                                      },
                                    })}
                                    onChange={onValueChange("institution")}
                                  >
                                    {institution?.map((x) => (
                                      <option key={x.code} value={x.code}>
                                        {x.name}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.institution && (
                                    <span className="err-item">
                                      {errors.institution.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        {selectedProvider?.providerId &&
                          selectedProvider?.providerId ===
                          PROVIDER_ID.ALLIANZ && (
                            <div className="field-item policy_number">
                              <div className="wrap-field">
                                <label htmlFor="student_number">
                                  Student ID
                                </label>
                                <div className="wrap-input-field">
                                  <input
                                    type="text"
                                    id="student_number"
                                    name="personal[student_number]"
                                    value={data.studentId}
                                    onChange={onValueChange("studentId")}
                                    maxLength={20}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    {data.partner.length > 0 && !isMobile && (
                      <div>
                        <h4 className="form-title">Partner</h4>
                        <table className="table-partner border">
                          <tbody>
                            <tr>
                              <td className="th dependent-title">Title </td>
                              <td className="th">
                                First Name
                                <span className="text-required">*</span>
                              </td>
                              <td className="th">
                                Last Name
                                <span className="text-required">*</span>
                              </td>
                              <td className="th">
                                Date of birth
                                <span className="text-required">*</span>
                              </td>
                              <td className="th">
                                Passport
                                <span className="text-required">*</span>
                              </td>
                              <td className="th">
                                Gender<span className="text-required">*</span>
                              </td>
                              {selectedProvider?.providerId ===
                                PROVIDER_ID.ALLIANZ && (
                                  <td className="th">
                                    Country of issue
                                    <span className="text-required">*</span>
                                  </td>
                                )}
                            </tr>
                            {data.partner.map((x, index) => (
                              <tr key={index}>
                                <td>
                                  <select
                                    id={`partner_${index}_title`}
                                    name={`partner_${index}_title`}
                                    value={x.title}
                                    onChange={onValueChange(
                                      `partner[${index}].title`,
                                      "number",
                                      `partner_${index}_title`
                                    )}
                                  >
                                    {TITLE_LIST.map((x) => (
                                      <option key={x.value} value={x.value}>
                                        {x.label}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    name={`partner_${index}_firstName`}
                                    value={x.firstName}
                                    maxLength={24}
                                    {...register(`partner_${index}_firstName`, {
                                      required: VALIDATION.messages.required,
                                      pattern: {
                                        value: /^[A-Za-z\d_-\s]+$/,
                                        message:
                                          VALIDATION.messages.englishRequired,
                                      },
                                    })}
                                    onChange={onValueChange(
                                      `partner[${index}].firstName`,
                                      "string",
                                      `partner_${index}_firstName`
                                    )}
                                    type="text"
                                    placeholder="First Name"
                                  />
                                  {errors[`partner_${index}_firstName`] && (
                                    <span className="err-item">
                                      {
                                        errors[`partner_${index}_firstName`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <input
                                    name={`partner_${index}_lastName`}
                                    value={x.lastName}
                                    {...register(`partner_${index}_lastName`, {
                                      required: VALIDATION.messages.required,
                                      pattern: {
                                        value: /^[A-Za-z\d_-\s]+$/,
                                        message:
                                          VALIDATION.messages.englishRequired,
                                      },
                                    })}
                                    onChange={onValueChange(
                                      `partner[${index}].lastName`,
                                      "string",
                                      `partner_${index}_lastName`
                                    )}
                                    type="text"
                                    placeholder="Last Name"
                                  />
                                  {errors[`partner_${index}_lastName`] && (
                                    <span className="err-item">
                                      {
                                        errors[`partner_${index}_lastName`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                                <td className="td-birth-input">
                                  <span className="date-item">
                                    <select
                                      id=""
                                      name={`partner_${index}_birthday_day`}
                                      value={x.birthday.day}
                                      onChange={onValueChange(
                                        `partner[${index}].birthday.day`,
                                        "number",
                                        `partner_${index}_birthday_day`
                                      )}
                                    >
                                      {getDays(
                                        x.birthday.month,
                                        x.birthday.year
                                      ).map((day) => (
                                        <option key={day} value={day}>
                                          {day}
                                        </option>
                                      ))}
                                    </select>
                                  </span>
                                  <span className="date-item">
                                    <select
                                      value={x.birthday.month}
                                      onChange={onValueChange(
                                        `partner[${index}].birthday.month`,
                                        "number",
                                        `partner_${index}_birthday_month`
                                      )}
                                      id=""
                                      name={`partner_${index}_birthday_month`}
                                    >
                                      {MONTH_LIST.map((month) => (
                                        <option
                                          key={month.value}
                                          value={month.value}
                                        >
                                          {month.label}
                                        </option>
                                      ))}
                                    </select>
                                  </span>
                                  <span className="date-item">
                                    <select
                                      value={x.birthday.year}
                                      name={`partner_${index}_birthday_year`}
                                      onChange={onValueChange(
                                        `partner[${index}].birthday.year`,
                                        "number",
                                        `partner_${index}_birthday_year`
                                      )}
                                    >
                                      {years.map((year) => (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </select>
                                  </span>
                                </td>
                                <td>
                                  <input
                                    name={`partner_${index}_passport`}
                                    value={x.passport}
                                    maxLength={10}
                                    {...register(`partner_${index}_passport`, {
                                      required: VALIDATION.messages.required,
                                    })}
                                    onChange={onValueChange(
                                      `partner[${index}].passport`,
                                      "string",
                                      `partner_${index}_passport`
                                    )}
                                    type="text"
                                    placeholder="Passport"
                                  />
                                  {errors[`partner_${index}_passport`] && (
                                    <span className="err-item">
                                      {
                                        errors[`partner_${index}_passport`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <select
                                    name={`partner_${index}_gender`}
                                    value={x.gender}
                                    onChange={onValueChange(
                                      `partner[${index}].gender`,
                                      "number",
                                      `partner_${index}_gender`
                                    )}
                                  >
                                    {(data.providerId === PROVIDER_ID.ALLIANZ
                                      ? ALLIANZ_GENDER_LIST
                                      : GENDER_LIST
                                    ).map((x) => (
                                      <option key={x.value} value={x.value}>
                                        {x.label}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                {selectedProvider?.providerId ===
                                  PROVIDER_ID.ALLIANZ && (
                                    <td>
                                      <select
                                        name={`partner_${index}_nationality`}
                                        value={x.nationality}
                                        {...register(
                                          `partner_${index}_nationality`,
                                          {
                                            validate: {
                                              required: (_) => {
                                                const data = getValues();
                                                if (
                                                  !data[
                                                  `partner_${index}_nationality`
                                                  ]
                                                )
                                                  return VALIDATION.messages
                                                    .required;
                                                return true;
                                              },
                                            },
                                          }
                                        )}
                                        onChange={onValueChange(
                                          `partner[${index}].nationality`,
                                          "string",
                                          `partner_${index}_nationality`
                                        )}
                                      >
                                        {nationality.map((x) => (
                                          <option key={x.code} value={x.code}>
                                            {x.name}
                                          </option>
                                        ))}
                                      </select>
                                      {errors[`partner_${index}_nationality`] && (
                                        <span className="err-item">
                                          {
                                            errors[`partner_${index}_nationality`]
                                              .message
                                          }
                                        </span>
                                      )}
                                    </td>
                                  )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {data.partner.length > 0 && isMobile && (
                      <div>
                        <h4 className="form-title">Partner</h4>
                        {data.partner.map((x, index) => (
                          <table
                            key={index}
                            className="table-partner border stacktable small-only"
                          >
                            <tbody>
                              <tr className="">
                                <td className="st-key">Title </td>
                                <td className="st-val ">
                                  <select
                                    id={`partner_${index}_title`}
                                    name={`partner_${index}_title`}
                                    value={x.title}
                                    onChange={onValueChange(
                                      `partner_${index}_title`,
                                      "number",
                                      `partner_${index}_title`
                                    )}
                                  >
                                    {TITLE_LIST.map((x) => (
                                      <option key={x.value} value={x.value}>
                                        {x.label}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              <tr className="">
                                <td className="st-key">
                                  First Name
                                  <span className="text-required">*</span>
                                </td>
                                <td className="st-val ">
                                  <input
                                    name={`partner_${index}_firstName`}
                                    value={x.firstName}
                                    maxLength={24}
                                    {...register(`partner_${index}_firstName`, {
                                      required: VALIDATION.messages.required,
                                      pattern: {
                                        value: /^[A-Za-z\d_-\s]+$/,
                                        message:
                                          VALIDATION.messages.englishRequired,
                                      },
                                    })}
                                    onChange={onValueChange(
                                      `partner[${index}].firstName`,
                                      "string",
                                      `partner_${index}_firstName`
                                    )}
                                    type="text"
                                    placeholder="First Name"
                                  />
                                  {errors[`partner_${index}_firstName`] && (
                                    <span className="err-item">
                                      {
                                        errors[`partner_${index}_firstName`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                              </tr>
                              <tr className="">
                                <td className="st-key">
                                  Last Name
                                  <span className="text-required">*</span>
                                </td>
                                <td className="st-val ">
                                  <input
                                    name={`partner_${index}_lastName`}
                                    value={x.lastName}
                                    {...register(`partner_${index}_lastName`, {
                                      required: VALIDATION.messages.required,
                                      pattern: {
                                        value: /^[A-Za-z\d_-\s]+$/,
                                        message:
                                          VALIDATION.messages.englishRequired,
                                      },
                                    })}
                                    onChange={onValueChange(
                                      `partner[${index}].lastName`,
                                      "string",
                                      `partner_${index}_lastName`
                                    )}
                                    type="text"
                                    placeholder="Last Name"
                                  />
                                  {errors[`partner_${index}_lastName`] && (
                                    <span className="err-item">
                                      {
                                        errors[`partner_${index}_lastName`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                              </tr>
                              <tr className="">
                                <td className="st-key">
                                  Date of birth
                                  <span className="text-required">*</span>
                                </td>
                                <td className="st-val td-birth-input">
                                  <span className="date-item">
                                    <select
                                      id=""
                                      name={`partner_${index}_birthday_day`}
                                      value={x.birthday.day}
                                      onChange={onValueChange(
                                        `partner[${index}].birthday.day`,
                                        "number",
                                        `partner_${index}_birthday_day`
                                      )}
                                    >
                                      {getDays(
                                        x.birthday.month,
                                        x.birthday.year
                                      ).map((day) => (
                                        <option key={day} value={day}>
                                          {day}
                                        </option>
                                      ))}
                                    </select>
                                  </span>
                                  <span className="date-item">
                                    <select
                                      value={x.birthday.month}
                                      onChange={onValueChange(
                                        `partner[${index}].birthday.month`,
                                        "number",
                                        `partner_${index}_birthday_month`
                                      )}
                                      id=""
                                      name={`partner_${index}_birthday_month`}
                                    >
                                      {MONTH_LIST.map((month) => (
                                        <option
                                          key={month.value}
                                          value={month.value}
                                        >
                                          {month.label}
                                        </option>
                                      ))}
                                    </select>
                                  </span>
                                  <span className="date-item">
                                    <select
                                      value={x.birthday.year}
                                      name={`partner_${index}_birthday_year`}
                                      onChange={onValueChange(
                                        `partner[${index}].birthday.year`,
                                        "number",
                                        `partner_${index}_birthday_year`
                                      )}
                                    >
                                      {years.map((year) => (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </select>
                                  </span>
                                </td>
                              </tr>
                              <tr className="">
                                <td className="st-key">
                                  Passport
                                  <span className="text-required">*</span>
                                </td>
                                <td className="st-val ">
                                  <input
                                    name={`partner_${index}_passport`}
                                    value={x.passport}
                                    maxLength={10}
                                    {...register(`partner_${index}_passport`, {
                                      required: VALIDATION.messages.required,
                                    })}
                                    onChange={onValueChange(
                                      `partner[${index}].passport`,
                                      "string",
                                      `partner_${index}_passport`
                                    )}
                                    type="text"
                                    placeholder="Passport"
                                  />
                                  {errors[`partner_${index}_passport`] && (
                                    <span className="err-item">
                                      {
                                        errors[`partner_${index}_passport`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                              </tr>
                              <tr className="">
                                <td className="st-key">
                                  Gender<span className="text-required">*</span>
                                </td>
                                <td className="st-val ">
                                  <select
                                    name={`partner_${index}_gender`}
                                    value={x.gender}
                                    onChange={onValueChange(
                                      `partner[${index}].gender`,
                                      "number",
                                      `partner_${index}_gender`
                                    )}
                                  >
                                    {(data.providerId === PROVIDER_ID.ALLIANZ
                                      ? ALLIANZ_GENDER_LIST
                                      : GENDER_LIST
                                    ).map((x) => (
                                      <option key={x.value} value={x.value}>
                                        {x.label}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              {selectedProvider?.providerId ===
                                PROVIDER_ID.ALLIANZ && (
                                  <tr className="">
                                    <td className="st-key">
                                      Country of issue
                                      <span className="text-required">*</span>
                                    </td>
                                    <td className="st-val ">
                                      <select
                                        name={`partner_${index}_nationality`}
                                        value={x.nationality}
                                        {...register(
                                          `partner_${index}_nationality`,
                                          {
                                            validate: {
                                              required: (_) => {
                                                const data = getValues();
                                                if (
                                                  !data[
                                                  `partner_${index}_nationality`
                                                  ]
                                                )
                                                  return VALIDATION.messages
                                                    .required;
                                                return true;
                                              },
                                            },
                                          }
                                        )}
                                        onChange={onValueChange(
                                          `partner[${index}].nationality`,
                                          "string",
                                          `partner_${index}_nationality`
                                        )}
                                      >
                                        {nationality.map((x) => (
                                          <option key={x.value} value={x.value}>
                                            {x.name}
                                          </option>
                                        ))}
                                      </select>
                                      {errors[`partner_${index}_nationality`] && (
                                        <span className="err-item">
                                          {
                                            errors[`partner_${index}_nationality`]
                                              .message
                                          }
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        ))}
                      </div>
                    )}
                    {data.children.length > 0 && !isMobile && (
                      <div>
                        <h4 className="form-title">Children</h4>
                        <table className="table-partner border">
                          <tbody>
                            <tr>
                              <td className="th dependent-title">Title </td>
                              <td className="th">
                                First Name
                                <span className="text-required">*</span>
                              </td>
                              <td className="th">
                                Last Name
                                <span className="text-required">*</span>
                              </td>
                              <td className="th">
                                Date of birth
                                <span className="text-required">*</span>
                              </td>
                              <td className="th">
                                Passport<span className="text-required">*</span>
                              </td>
                              <td className="th">
                                Gender<span className="text-required">*</span>
                              </td>
                              {selectedProvider?.providerId ===
                                PROVIDER_ID.ALLIANZ && (
                                  <td className="th">
                                    Country of issue
                                    <span className="text-required">*</span>
                                  </td>
                                )}
                            </tr>
                            {data.children.map((x, index) => (
                              <tr key={index}>
                                <td>
                                  <select
                                    id={`children_${index}_title`}
                                    name={`children_${index}_title`}
                                    value={x.title}
                                    onChange={onValueChange(
                                      `children[${index}].title`,
                                      "number",
                                      `children_${index}_title`
                                    )}
                                  >
                                    {TITLE_LIST.map((x) => (
                                      <option key={x.value} value={x.value}>
                                        {x.label}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    value={x.firstName}
                                    name={`children_${index}_firstName`}
                                    maxLength={24}
                                    {...register(
                                      `children_${index}_firstName`,
                                      {
                                        required: VALIDATION.messages.required,
                                        pattern: {
                                          value: /^[A-Za-z\d_-\s]+$/,
                                          message:
                                            VALIDATION.messages.englishRequired,
                                        },
                                      }
                                    )}
                                    onChange={onValueChange(
                                      `children[${index}].firstName`,
                                      "string",
                                      `children_${index}_firstName`
                                    )}
                                    type="text"
                                    placeholder="First Name"
                                  />
                                  {errors[`children_${index}_firstName`] && (
                                    <span className="err-item">
                                      {
                                        errors[`children_${index}_firstName`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <input
                                    value={x.lastName}
                                    name={`children_${index}_lastName`}
                                    {...register(`children_${index}_lastName`, {
                                      required: VALIDATION.messages.required,
                                      pattern: {
                                        value: /^[A-Za-z\d_-\s]+$/,
                                        message:
                                          VALIDATION.messages.englishRequired,
                                      },
                                    })}
                                    onChange={onValueChange(
                                      `children[${index}].lastName`,
                                      "string",
                                      `children_${index}_lastName`
                                    )}
                                    type="text"
                                    placeholder="Last Name"
                                  />
                                  {errors[`children_${index}_lastName`] && (
                                    <span className="err-item">
                                      {
                                        errors[`children_${index}_lastName`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                                <td className="td-birth-input">
                                  <span className="date-item">
                                    <select
                                      id=""
                                      name={`children_${index}_birthday_day`}
                                      value={x.birthday.day}
                                      onChange={onValueChange(
                                        `children[${index}].birthday.day`,
                                        "number",
                                        `children_${index}_birthday_day`
                                      )}
                                    >
                                      {getDays(
                                        x.birthday.month,
                                        x.birthday.year
                                      ).map((day) => (
                                        <option key={day} value={day}>
                                          {day}
                                        </option>
                                      ))}
                                    </select>
                                  </span>
                                  <span className="date-item">
                                    <select
                                      id=""
                                      name={`children_${index}_birthday_month`}
                                      value={x.birthday.month}
                                      onChange={onValueChange(
                                        `children[${index}].birthday.month`,
                                        "number",
                                        `children_${index}_birthday_month`
                                      )}
                                    >
                                      {MONTH_LIST.map((month) => (
                                        <option
                                          key={month.value}
                                          value={month.value}
                                        >
                                          {month.label}
                                        </option>
                                      ))}
                                    </select>
                                  </span>
                                  <span className="date-item">
                                    <select
                                      name={`children_${index}_birthday_year`}
                                      value={x.birthday.year}
                                      onChange={onValueChange(
                                        `children[${index}].birthday.year`,
                                        "number",
                                        `children_${index}_birthday_year`
                                      )}
                                    >
                                      {years.map((year) => (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </select>
                                  </span>
                                  <input
                                    type="hidden"
                                    id={`children_${index}_birth`}
                                    name={`children_${index}_birth`}
                                    {...register(`children_${index}_birth`, {
                                      validate: {
                                        chidrenAge: (_) => {
                                          var birthday = new Date(
                                                    `${
                                                        x.birthday.year
                                            }-${x.birthday.month
                                              .toString()
                                              .padStart(
                                                2,
                                                "0"
                                              )}-${x.birthday.day
                                                .toString()
                                                .padStart(2, "0")}`
                                          );

                                          var age = getAge(birthday);

                                          if (age >= 18)
                                            return VALIDATION.messages
                                              .invalidChildrenAge;
                                          return true;
                                        },
                                      },
                                    })}
                                  />
                                  {errors[`children_${index}_birth`] && (
                                    <span className="err-item">
                                      {
                                        errors[`children_${index}_birth`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <input
                                    value={x.passport}
                                    name={`children_${index}_passport`}
                                    maxLength={10}
                                    {...register(`children_${index}_passport`, {
                                      required: VALIDATION.messages.required,
                                    })}
                                    onChange={onValueChange(
                                      `children[${index}].passport`,
                                      "string",
                                      `children_${index}_passport`
                                    )}
                                    type="text"
                                    placeholder="Passport"
                                  />
                                  {errors[`children_${index}_passport`] && (
                                    <span className="err-item">
                                      {
                                        errors[`children_${index}_passport`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <select
                                    name={`children_${index}_gender`}
                                    value={x.gender}
                                    onChange={onValueChange(
                                      `children[${index}].gender`,
                                      "number",
                                      `children_${index}_gender`
                                    )}
                                  >
                                    {(data.providerId === PROVIDER_ID.ALLIANZ
                                      ? ALLIANZ_GENDER_LIST
                                      : GENDER_LIST
                                    ).map((x) => (
                                      <option key={x.value} value={x.value}>
                                        {x.label}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                {selectedProvider?.providerId ===
                                  PROVIDER_ID.ALLIANZ && (
                                    <td>
                                      <select
                                        name={`children_${index}_nationality`}
                                        value={x.nationality}
                                        {...register(
                                          `children_${index}_nationality`,
                                          {
                                            validate: {
                                              required: (_) => {
                                                const data = getValues();
                                                if (
                                                  !data[
                                                  `children_${index}_nationality`
                                                  ]
                                                )
                                                  return VALIDATION.messages
                                                    .required;
                                                return true;
                                              },
                                            },
                                          }
                                        )}
                                        onChange={onValueChange(
                                          `children[${index}].nationality`,
                                          "string",
                                          `children_${index}_nationality`
                                        )}
                                      >
                                        {nationality.map((x) => (
                                          <option key={x.code} value={x.code}>
                                            {x.name}
                                          </option>
                                        ))}
                                      </select>
                                      {errors[
                                        `children_${index}_nationality`
                                      ] && (
                                          <span className="err-item">
                                            {
                                              errors[
                                                `children_${index}_nationality`
                                              ].message
                                            }
                                          </span>
                                        )}
                                    </td>
                                  )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {data.children.length > 0 && isMobile && (
                      <div>
                        <h4 className="form-title">Children</h4>
                        {data.children.map((x, index) => (
                          <table
                            key={index}
                            className="table-partner border stacktable small-only"
                          >
                            <tbody>
                              <tr className="">
                                <td className="st-key">Title </td>
                                <td className="st-val ">
                                  <select
                                    id={`children_${index}_title`}
                                    name={`children_${index}_title`}
                                    value={x.title}
                                    onChange={onValueChange(
                                      `children[${index}].title`,
                                      "number",
                                      `children_${index}_title`
                                    )}
                                  >
                                    {TITLE_LIST.map((x) => (
                                      <option key={x.value} value={x.value}>
                                        {x.label}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              <tr className="">
                                <td className="st-key">
                                  First Name
                                  <span className="text-required">*</span>
                                </td>
                                <td className="st-val ">
                                  <input
                                    value={x.firstName}
                                    name={`children_${index}_firstName`}
                                    {...register(
                                      `children_${index}_firstName`,
                                      {
                                        required: VALIDATION.messages.required,
                                      }
                                    )}
                                    onChange={onValueChange(
                                      `children[${index}].firstName`,
                                      "string",
                                      `children_${index}_firstName`
                                    )}
                                    type="text"
                                    placeholder="First Name"
                                  />
                                  {errors[`children_${index}_firstName`] && (
                                    <span className="err-item">
                                      {
                                        errors[`children_${index}_firstName`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                              </tr>
                              <tr className="">
                                <td className="st-key">
                                  Last Name
                                  <span className="text-required">*</span>
                                </td>
                                <td className="st-val ">
                                  <input
                                    value={x.lastName}
                                    name={`children_${index}_lastName`}
                                    {...register(`children_${index}_lastName`, {
                                      required: VALIDATION.messages.required,
                                    })}
                                    onChange={onValueChange(
                                      `children[${index}].lastName`,
                                      "string",
                                      `children_${index}_lastName`
                                    )}
                                    type="text"
                                    placeholder="Last Name"
                                  />
                                  {errors[`children_${index}_lastName`] && (
                                    <span className="err-item">
                                      {
                                        errors[`children_${index}_lastName`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                              </tr>
                              <tr className="">
                                <td className="st-key">
                                  Date of birth
                                  <span className="text-required">*</span>
                                </td>
                                <td className="st-val td-birth-input">
                                  <span className="date-item">
                                    <select
                                      id=""
                                      name={`children_${index}_birthday_day`}
                                      value={x.birthday.day}
                                      onChange={onValueChange(
                                        `children[${index}].birthday.day`,
                                        "number",
                                        `children_${index}_birthday_day`
                                      )}
                                    >
                                      {getDays(
                                        x.birthday.month,
                                        x.birthday.year
                                      ).map((day) => (
                                        <option key={day} value={day}>
                                          {day}
                                        </option>
                                      ))}
                                    </select>
                                  </span>
                                  <span className="date-item">
                                    <select
                                      id=""
                                      name={`children_${index}_birthday_month`}
                                      value={x.birthday.month}
                                      onChange={onValueChange(
                                        `children[${index}].birthday.month`,
                                        "number",
                                        `children_${index}_birthday_month`
                                      )}
                                    >
                                      {MONTH_LIST.map((month) => (
                                        <option
                                          key={month.value}
                                          value={month.value}
                                        >
                                          {month.label}
                                        </option>
                                      ))}
                                    </select>
                                  </span>
                                  <span className="date-item">
                                    <select
                                      name={`children_${index}_birthday_year`}
                                      value={x.birthday.year}
                                      onChange={onValueChange(
                                        `children[${index}].birthday.year`,
                                        "number",
                                        `children_${index}_birthday_year`
                                      )}
                                    >
                                      {years.map((year) => (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </select>
                                  </span>
                                  <input
                                    type="hidden"
                                    id={`children_${index}_birth`}
                                    name={`children_${index}_birth`}
                                    {...register(`children_${index}_birth`, {
                                      validate: {
                                        chidrenAge: (_) => {
                                          var birthday = new Date(
                                                    `${
                                                        x.birthday.year
                                            }-${x.birthday.month
                                              .toString()
                                              .padStart(
                                                2,
                                                "0"
                                              )}-${x.birthday.day
                                                .toString()
                                                .padStart(2, "0")}`
                                          );

                                          var age = getAge(birthday);

                                          if (age >= 18)
                                            return VALIDATION.messages
                                              .invalidChildrenAge;
                                          return true;
                                        },
                                      },
                                    })}
                                  />
                                  {errors[`children_${index}_birth`] && (
                                    <span className="err-item">
                                      {
                                        errors[`children_${index}_birth`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                              </tr>
                              <tr className="">
                                <td className="st-key">
                                  Passport
                                  <span className="text-required">*</span>
                                </td>
                                <td className="st-val ">
                                  <input
                                    value={x.passport}
                                    maxLength={10}
                                    name={`children_${index}_passport`}
                                    {...register(`children_${index}_passport`, {
                                      required: VALIDATION.messages.required,
                                    })}
                                    onChange={onValueChange(
                                      `children[${index}].passport`,
                                      "string",
                                      `children_${index}_passport`
                                    )}
                                    type="text"
                                    placeholder="Passport"
                                  />
                                  {errors[`children_${index}_passport`] && (
                                    <span className="err-item">
                                      {
                                        errors[`children_${index}_passport`]
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                              </tr>
                              <tr className="">
                                <td className="st-key">
                                  Gender<span className="text-required">*</span>
                                </td>
                                <td className="st-val ">
                                  <select
                                    name={`children_${index}_gender`}
                                    value={x.gender}
                                    onChange={onValueChange(
                                      `children_${index}_gender`,
                                      "number",
                                      `children_${index}_gender`
                                    )}
                                  >
                                    {(data.providerId === PROVIDER_ID.ALLIANZ
                                      ? ALLIANZ_GENDER_LIST
                                      : GENDER_LIST
                                    ).map((x) => (
                                      <option key={x.value} value={x.value}>
                                        {x.label}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              {selectedProvider?.providerId ===
                                PROVIDER_ID.ALLIANZ && (
                                  <tr className="">
                                    <td className="st-key">
                                      Country of issue
                                      <span className="text-required">*</span>
                                    </td>
                                    <td className="st-val ">
                                      <select
                                        name={`children_${index}_nationality`}
                                        value={x.nationality}
                                        {...register(
                                          `children_${index}_nationality`,
                                          {
                                            validate: {
                                              required: (_) => {
                                                const data = getValues();
                                                if (
                                                  !data[
                                                  `children_${index}_nationality`
                                                  ]
                                                )
                                                  return VALIDATION.messages
                                                    .required;
                                                return true;
                                              },
                                            },
                                          }
                                        )}
                                        onChange={onValueChange(
                                          `children[${index}].nationality`,
                                          "string",
                                          `children_${index}_nationality`
                                        )}
                                      >
                                        {nationality.map((x) => (
                                          <option key={x.code} value={x.code}>
                                            {x.name}
                                          </option>
                                        ))}
                                      </select>
                                      {errors[
                                        `children_${index}_nationality`
                                      ] && (
                                          <span className="err-item">
                                            {
                                              errors[
                                                `children_${index}_nationality`
                                              ].message
                                            }
                                          </span>
                                        )}
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        ))}
                      </div>
                    )}
                    <h4 className="form-title">Contact Details</h4>
                    {selectedProvider?.providerId !== PROVIDER_ID.ALLIANZ && (
                      <div>
                        <p className="decs">
                          Please make sure your email address is the right one
                          because your certificate and membership card will be
                          sent to your below email.
                        </p>
                        <p className="decs">
                          If you have the address in Australia, let us know to
                          send the membership card
                        </p>
                        <div className="field-item" style={{ margin: "0px" }}>
                          <div className="">
                            <input
                              id="not-au"
                              style={{
                                float: "left",
                                width: "auto",
                                marginTop: "5px",
                              }}
                              type="checkbox"
                              name="personal[not_au]"
                              value={!data.hasAustralianAddress}
                              onChange={onValueChange(
                                "hasAustralianAddress",
                                "boolean"
                              )}
                            />
                          </div>
                          <label
                            htmlFor="not-au"
                            style={{
                              float: "left",
                              fontWeight: 100,
                              width: "auto",
                            }}
                          >
                            I do not have an Australian address
                          </label>
                        </div>
                      </div>
                    )}
                    <div className="list-field list-field-contact">
                      <div className="field-item inside_au ">
                        <input
                          type="hidden"
                          name="personal[inside_au]"
                          value="Yes"
                        />
                      </div>
                      {data.hasAustralianAddress &&
                        selectedProvider?.providerId !==
                        PROVIDER_ID.ALLIANZ && (
                          <div id="form-address-au" className="form-au">
                            <div className="field-item">
                              <div className="wrap-field">
                                <label htmlFor="address">
                                  Street address
                                  <span className="required-address text-required">
                                    *
                                  </span>
                                </label>
                                <div className="wrap-input-field">
                                  <input
                                    className="input-au"
                                    id="address"
                                    {...register("streetAndress", {
                                      validate: {
                                        required: (_) => {
                                          const { streetAndress } = getValues();
                                          if (
                                            !streetAndress &&
                                            data.hasAustralianAddress
                                          )
                                            return VALIDATION.messages.required;
                                          return true;
                                        },
                                      },
                                    })}
                                    value={data.streetAndress}
                                    onChange={onValueChange("streetAndress")}
                                    type="text"
                                    name="au_address[street]"
                                  />
                                  {errors.streetAndress && (
                                    <span className="err-item">
                                      {errors.streetAndress.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="field-item">
                              <div className="wrap-field">
                                <label htmlFor="suburd">
                                  City / Suburb
                                  <span className="required-address text-required">
                                    *
                                  </span>
                                </label>
                                <div className="wrap-input-field">
                                  <input
                                    className="input-au"
                                    id="suburd"
                                    value={data.city}
                                    {...register("city", {
                                      validate: {
                                        required: (_) => {
                                          const { city } = getValues();
                                          if (
                                            !city &&
                                            data.hasAustralianAddress
                                          )
                                            return VALIDATION.messages.required;
                                          return true;
                                        },
                                      },
                                    })}
                                    onChange={onValueChange("city")}
                                    type="text"
                                    name="au_address[suburd]"
                                  />
                                  {errors.city && (
                                    <span className="err-item">
                                      {errors.city.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="field-item">
                              <div className="wrap-field">
                                <label htmlFor="state">
                                  State
                                  <span className="required-address text-required">
                                    *
                                  </span>
                                </label>
                                <div className="wrap-input-field">
                                  <input
                                    className="input-au"
                                    id="state"
                                    value={data.state}
                                    {...register("state", {
                                      validate: {
                                        required: (_) => {
                                          const { state } = getValues();
                                          if (
                                            !state &&
                                            data.hasAustralianAddress
                                          )
                                            return VALIDATION.messages.required;
                                          return true;
                                        },
                                      },
                                    })}
                                    onChange={onValueChange("state")}
                                    type="text"
                                    name="au_address[state]"
                                  />
                                  {errors.state && (
                                    <span className="err-item">
                                      {errors.state.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="field-item">
                              <div className="wrap-field">
                                <label htmlFor="postcode">
                                  Postcode
                                  <span className="required-address text-required">
                                    *
                                  </span>
                                </label>
                                <div className="wrap-input-field">
                                  <input
                                    className="input-au"
                                    id="postcode"
                                    value={data.postCode}
                                    {...register("postCode", {
                                      validate: {
                                        required: (_) => {
                                          const { postCode } = getValues();
                                          if (
                                            !postCode &&
                                            data.hasAustralianAddress
                                          )
                                            return VALIDATION.messages.required;
                                          return true;
                                        },
                                        pattern: (_) => {
                                          const { postCode } = getValues();
                                          const regex = new RegExp(
                                            /^(0[289]([0-9]{2})|[1-9]([0-9]{3}))$/
                                          );
                                          if (
                                            postCode &&
                                            data.hasAustralianAddress &&
                                            !regex.test(postCode)
                                          )
                                            return "Please input Australia Postcode";
                                          return true;
                                        },
                                      },
                                    })}
                                    onChange={onValueChange("postCode")}
                                    type="text"
                                    name="au_address[postcode]"
                                  />
                                  {errors.postCode && (
                                    <span className="err-item">
                                      {errors.postCode.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      <div className="field-item address_manual">
                        <div className="wrap-field">
                          <label htmlFor="phone-number">
                            Mobile Phone Number
                          </label>
                          <div className="wrap-input-field">
                            <input
                              id="phone-number"
                              value={data.phone}
                              {...register("phone", {
                                pattern: {
                                  value: /^\d{2,4}(?:\s?\d{1,3})+\d{1,4}$/,
                                  message:
                                    "Entered value does not match phone format",
                                },
                              })}
                              onChange={onValueChange("phone")}
                              type="text"
                              name="personal[phone_number]"
                              placeholder="02 8283 0273"
                            />
                            {errors.phone && (
                              <span className="err-item">
                                {errors.phone.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="field-item">
                        <div className="wrap-field">
                          <label htmlFor="personal-email">
                            Client email<span className="text-required">*</span>
                          </label>
                          <div className="wrap-input-field">
                            <input
                              id="personal-email"
                              name="personal[email]"
                              value={data.email}
                              {...register("email", {
                                required: VALIDATION.messages.required,
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message:
                                    "Entered value does not match email format",
                                },
                                validate: (value) => {
                                  if (value.includes('@yahoo')) {
                                    return VALIDATION.messages.emailReceiverNotAllowed;
                                  }
                                  return true;
                                }
                              })}
                              onChange={onValueChange("email")}
                              type="text"
                              placeholder="jonh@gmail.com"
                            />
                            {errors.email && (
                              <span className="err-item">
                                {errors.email.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="field-item">
                        <div className="wrap-field">
                          <label htmlFor="email-confirmation">
                            Client email confirmation
                            <span className="text-required">*</span>
                          </label>
                          <div className="wrap-input-field">
                            <input
                              id="email-confirmation"
                              value={data.emailConfirmation}
                              {...register("emailConfirmation", {
                                validate: {
                                  required: (_) => {
                                    const { emailConfirmation } = getValues();
                                    if (!emailConfirmation)
                                      return VALIDATION.messages.required;
                                    return true;
                                  },
                                  match: (_) => {
                                    const { emailConfirmation, email } =
                                      getValues();
                                    if (emailConfirmation !== email)
                                      return "The client email confirmation does not match.";
                                    if (emailConfirmation.includes('@yahoo') || email.includes('@yahoo')) {
                                      return VALIDATION.messages.emailReceiverNotAllowed;
                                    }
                                    return true;
                                  },
                                },
                              })}
                              onChange={onValueChange("emailConfirmation")}
                              type="text"
                              name="personal[email_confirmation]"
                              placeholder="jonh@gmail.com"
                            />
                            {errors.emailConfirmation && (
                              <span className="err-item">
                                {errors.emailConfirmation.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <h4 className="title">Agent Information</h4>

                      <div className="field-item">
                        <div className="wrap-field">
                          <label htmlFor="education-agent">
                            Education Agent
                          </label>
                          <div className="wrap-input-field">
                            <input
                              value={data.educationAgent}
                              onChange={onValueChange("educationAgent")}
                              name="personal[education_agent]"
                              id="education-institution"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="agent-information">
                        <div className="field-item">
                          <div className="wrap-field">
                            <label htmlFor="agent-code">Agent Code</label>
                            <div className="wrap-input-field">
                              <input
                                id="agent-code"
                                type="text"
                                name="personal[agent_code]"
                                value={data.agentCode}
                                onChange={onValueChange("agentCode")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {data.agentCode != null &&
                        data.agentCode.trim() !== "" && (
                          <div className="field-item">
                            <div className="wrap-field">
                              <label htmlFor="personal-email">
                                Email will receive invoice
                              </label>
                              <div className="wrap-input-field">
                                <input
                                  id="personal-email"
                                  name="personal[email]"
                                  value={data.emailReceiveInvoice}
                                  {...register("emailReceiveInvoice", {
                                    validate: {
                                      match: (_) => {
                                        const { emailReceiveInvoice } =
                                          getValues();
                                        var valid =
                                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                            emailReceiveInvoice
                                          );
                                        if (!valid && emailReceiveInvoice)
                                          return "Entered value does not match email format";
                                        return true;
                                      },
                                    },
                                  })}
                                  onChange={onValueChange(
                                    "emailReceiveInvoice"
                                  )}
                                  type="text"
                                  placeholder="jonh@gmail.com"
                                />
                                {errors.emailReceiveInvoice && (
                                  <span className="err-item">
                                    {errors.emailReceiveInvoice.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                      <h4 className="title">Transaction Documents</h4>

                      <span>
                        <b>Note:</b> Each country requires different types of
                        document. Thus, please choose your Date of birth, the
                        country where your payment is made, and Membership
                        status.
                      </span>
                      <div className="field-item country ">
                        <div className="wrap-field">
                          <label htmlFor="country">
                            Payment country
                            <span className="required-address text-required">
                              *
                            </span>
                          </label>
                          <div className="wrap-input-field">
                            <select
                              className="select-country"
                              id="country"
                              value={data?.paymentCountry}
                              {...register("paymentCountry", {
                                validate: {
                                  required: (_) => {
                                    const { paymentCountry } = getValues();
                                    if (!paymentCountry)
                                      return VALIDATION.messages.required;
                                    return true;
                                  },
                                },
                              })}
                              onChange={onValueChange("paymentCountry")}
                              name="personal[country]"
                            >
                              {paymentCountry.map((x) => (
                                <option key={x.code} value={x.code}>
                                  {x.name}
                                </option>
                              ))}
                            </select>
                            {errors.paymentCountry && (
                              <span className="err-item">
                                {errors.paymentCountry.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {selectedProvider?.providerId &&
                        (selectedProvider?.providerId ===
                          PROVIDER_ID.MEDIBANK ||
                          selectedProvider?.providerId === PROVIDER_ID.AHM ||
                          selectedProvider?.providerId === PROVIDER_ID.NIB) && (
                          <div className="field-item policy_select ">
                            <div className="wrap-field">
                              <label htmlFor="policy_select">
                                Are you an existing member of{" "}
                                {selectedProvider?.providerName}?
                              </label>
                              <select
                                id="policy_select"
                                name="personal[policy_select]"
                                value={data.existingMember}
                                {...register("existingMember")}
                                onChange={onValueChange(
                                  "existingMember",
                                  "boolean"
                                )}
                              >
                                <option key="Y" value="true">
                                  Yes
                                </option>
                                <option key="N" value="false">
                                  No
                                </option>
                              </select>
                            </div>
                          </div>
                        )}
                      {selectedProvider?.providerId &&
                        (selectedProvider?.providerId ===
                          PROVIDER_ID.MEDIBANK ||
                          selectedProvider?.providerId === PROVIDER_ID.AHM ||
                          selectedProvider?.providerId === PROVIDER_ID.NIB) &&
                        data.existingMember === true && (
                          <div className="field-item policy_number">
                            <div className="wrap-field">
                              <label htmlFor="policy_number">
                                Policy number
                                {data.existingMember && (
                                  <span className="text-required">*</span>
                                )}
                              </label>
                              <div className="wrap-input-field">
                                <input
                                  type="text"
                                  name="personal[policy_number]"
                                  value={data.policyNumber}
                                  {...register("policyNumber", {
                                    validate: {
                                      required: (_) => {
                                        const { policyNumber, existingMember } =
                                          getValues();
                                        if (!policyNumber && existingMember)
                                          return VALIDATION.messages.required;
                                        return true;
                                      },
                                    },
                                  })}
                                  onChange={onValueChange("policyNumber")}
                                  id="policy_number"
                                />
                                {errors.policyNumber && (
                                  <span className="err-item">
                                    {errors.policyNumber.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      <div id="wrap" className="template-oshc upload-document">
                        <div className="fix-width">
                          <div className="main-apply">
                            <div className="wrap-payment">
                              <div className="payment-cash">
                                <Collapse
                                  title="Passport (Both yours and your partner)"
                                  required={data.uploadRequires[0]}
                                  isExpanded={data.expanedIndexs[0]}
                                  onClick={() => handleExpand(0)}
                                  error={errors.passportFiles}
                                  className="upload-document-first-item"
                                >
                                  <Upload
                                    {...register("passportFiles", {
                                      validate: {
                                        required: (_) => {
                                          const { passportFiles } = getValues();
                                          if (
                                            (passportFiles == null ||
                                              passportFiles.length === 0) &&
                                            data.uploadRequires[0]
                                          )
                                            return VALIDATION.messages.required;
                                          return true;
                                        },
                                      },
                                    })}
                                    type={3}
                                    inputId="passportFiles"
                                    filesChanged={(v) =>
                                      onFilesChanged(v, "passportFiles")
                                    }
                                  />
                                </Collapse>
                                <Collapse
                                  title="Offer letter or COE (Confirmation of Enrollment)"
                                  required={data.uploadRequires[1]}
                                  isExpanded={data.expanedIndexs[1]}
                                  onClick={() => handleExpand(1)}
                                  error={errors.offerLetterOrCOEFiles}
                                >
                                  <Upload
                                    {...register("offerLetterOrCOEFiles", {
                                      validate: {
                                        required: (_) => {
                                          const { offerLetterOrCOEFiles } =
                                            getValues();
                                          if (
                                            (offerLetterOrCOEFiles == null ||
                                              offerLetterOrCOEFiles.length ===
                                              0) &&
                                            data.uploadRequires[1]
                                          )
                                            return VALIDATION.messages.required;
                                          return true;
                                        },
                                      },
                                    })}
                                    type={2}
                                    inputId="offerLetterOrCOEFiles"
                                    filesChanged={(v) =>
                                      onFilesChanged(v, "offerLetterOrCOEFiles")
                                    }
                                  />
                                </Collapse>
                                <Collapse
                                  title="Birth Certificate"
                                  required={data.uploadRequires[2]}
                                  isExpanded={data.expanedIndexs[2]}
                                  onClick={() => handleExpand(2)}
                                  error={errors.birthCertificateFiles}
                                >
                                  <Upload
                                    {...register("birthCertificateFiles", {
                                      validate: {
                                        required: (_) => {
                                          const { birthCertificateFiles } =
                                            getValues();
                                          if (
                                            (birthCertificateFiles == null ||
                                              birthCertificateFiles.length ===
                                              0) &&
                                            data.uploadRequires[2]
                                          )
                                            return VALIDATION.messages.required;
                                          return true;
                                        },
                                      },
                                    })}
                                    type={11}
                                    key="birthCertificateFiles"
                                    filesChanged={(v) =>
                                      onFilesChanged(v, "birthCertificateFiles")
                                    }
                                  />
                                </Collapse>
                                <Collapse
                                  title="COI (Certificate of Insurance)"
                                  required={data.uploadRequires[3]}
                                  isExpanded={data.expanedIndexs[3]}
                                  onClick={() => handleExpand(3)}
                                  error={errors.coiFiles}
                                >
                                  <Upload
                                    {...register("coiFiles", {
                                      validate: {
                                        required: (_) => {
                                          const { coiFiles } = getValues();
                                          if (
                                            (coiFiles == null ||
                                              coiFiles.length === 0) &&
                                            data.uploadRequires[3]
                                          )
                                            return VALIDATION.messages.required;
                                          return true;
                                        },
                                      },
                                    })}
                                    type={10}
                                    inputId="coiFiles"
                                    filesChanged={(v) =>
                                      onFilesChanged(v, "coiFiles")
                                    }
                                  />
                                </Collapse>
                                <Collapse
                                  title="Identification of Mother or Father"
                                  required={data.uploadRequires[4]}
                                  isExpanded={data.expanedIndexs[4]}
                                  onClick={() => handleExpand(4)}
                                  error={
                                    errors.identificationOfMotherOrFatherFiles
                                  }
                                  className="upload-document-last-item"
                                >
                                  <Upload
                                    {...register(
                                      "identificationOfMotherOrFatherFiles",
                                      {
                                        validate: {
                                          required: (_) => {
                                            const {
                                              identificationOfMotherOrFatherFiles,
                                            } = getValues();
                                            if (
                                              (identificationOfMotherOrFatherFiles ==
                                                null ||
                                                identificationOfMotherOrFatherFiles.length ===
                                                0) &&
                                              data.uploadRequires[4]
                                            )
                                              return VALIDATION.messages
                                                .required;
                                            return true;
                                          },
                                        },
                                      }
                                    )}
                                    type={7}
                                    inputId="identificationOfMotherOrFatherFiles"
                                    filesChanged={(v) =>
                                      onFilesChanged(
                                        v,
                                        "identificationOfMotherOrFatherFiles"
                                      )
                                    }
                                  />
                                </Collapse>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="field-item terms_and_conditions ">
                        <span id="button-terms">
                          Terms &amp; Conditions (*)
                        </span>
                      </div>
                      {selectedProvider?.providerId &&
                        selectedProvider?.providerId ===
                          PROVIDER_ID.ALLIANZ && (
                          <div className="form-field field-item input_eligible ">
                            <div className="wrap-field">
                              <input
                                type="hidden"
                                name="meta_service[awarePreviousOnVisitorVisa]"
                                value="false"
                              />
                              <input
                                id="awarePreviousOnVisitorVisa"
                                name="meta_service[awarePreviousOnVisitorVisa]"
                                value={data.awarePreviousOnVisitorVisa}
                                {...register("awarePreviousOnVisitorVisa", {
                                  required: VALIDATION.messages.required,
                                })}
                                type="checkbox"
                                onChange={onValueChange(
                                  "awarePreviousOnVisitorVisa",
                                  "boolean",
                                )}
                              />
                              If the student has applied for a student visa and
                              is currently on a bridging visa but did not
                              previously hold a student visa, they are not
                              covered until their Student Visa is granted and
                              need to consider purchasing OVHC.
                            </div>
                            {errors.awarePreviousOnVisitorVisa && (
                              <span className="err-item">
                                {errors.awarePreviousOnVisitorVisa.message}
                              </span>
                            )}
                          </div>
                        )}
                      <div className="form-field field-item input_terms ">
                        <div className="wrap-field">
                          <input
                            type="hidden"
                            name="meta_service[terms][label]"
                            value="terms"
                          />
                          <input
                            type="hidden"
                            name="meta_service[terms][class]"
                            value=""
                          />
                          <input
                            type="hidden"
                            name="meta_service[terms][required]"
                            value="check"
                          />
                          <input
                            type="hidden"
                            name="meta_service[terms]"
                            value="false"
                          />
                          <input
                            id="terms"
                            name="meta_service[terms]"
                            value={data.readTermsPolicy}
                            type="checkbox"
                            {...register("readTermsPolicy", {
                              required: VALIDATION.messages.required,
                            })}
                            onChange={onValueChange(
                              "readTermsPolicy",
                              "boolean"
                            )}
                          />
                          I have read, understood and agreed to the
                          <a
                            href="https://oshcstudents.com.au/terms-and-conditons"
                            rel="noreferrer"
                            target="_blank"
                            style={{ margin: "0 5px" }}
                          >
                            Terms and conditions
                          </a>
                          as well as the Policy Wording
                        </div>
                        {errors.readTermsPolicy && (
                          <span className="err-item">
                            {errors.readTermsPolicy.message}
                          </span>
                        )}
                      </div>
                      <div className="form-field field-item input_confirm ">
                        <div className="wrap-field">
                          <input
                            type="hidden"
                            name="meta_service[confirm]"
                            value="false"
                          />
                          <input
                            id="confirm"
                            name="meta_service[confirm]"
                            value={data.confirmedInformation}
                            type="checkbox"
                            {...register("confirmedInformation", {
                              required: VALIDATION.messages.required,
                            })}
                            onChange={onValueChange(
                              "confirmedInformation",
                              "boolean"
                            )}
                          />
                          I confirm that the information given in this form is
                          true, complete and accurate.
                        </div>
                        {errors.confirmedInformation && (
                          <span className="err-item">
                            {errors.confirmedInformation.message}
                          </span>
                        )}
                      </div>
                      <div className="form-field field-item input_eligible ">
                        <div className="wrap-field">
                          <input
                            type="hidden"
                            name="meta_service[eligible]"
                            value="false"
                          />
                          <input
                            id="eligible"
                            name="meta_service[eligible]"
                            value={data.acceptPolicyForDependentVisa}
                            {...register("acceptPolicyForDependentVisa", {
                              required: VALIDATION.messages.required,
                            })}
                            type="checkbox"
                            onChange={onValueChange(
                              "acceptPolicyForDependentVisa",
                              "boolean"
                            )}
                          />
                          If you hold a student dependent visa, you must be
                          insured under the same policy as the main student visa
                          holder. You are only eligible to hold a single OSHC
                          policy if you are the primary visa holder.
                        </div>
                        {errors.acceptPolicyForDependentVisa && (
                          <span className="err-item">
                            {errors.acceptPolicyForDependentVisa.message}
                          </span>
                        )}
                      </div>

                      <div className="form-field button">
                        <input
                          className="button-item button-submit"
                          type="submit"
                          name="submit_apply"
                          value="Continue"
                        />
                        <button
                          className="button-item button-cancel"
                          onClick={onBack}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner
        text="Please wait, we are processing your invoice."
        isShowing={isProcessing}
      />
      <CustomModal
        isShowing={isShowingLocationAnnouncementModal}
        toggle={toggleLocationAnnouncementModal}
        content={locationAnnouncementData}
      />
      <CustomModal
        isShowing={isShowingConfirmModal}
        toggle={toggleConfirmModal}
        header="Confirmation"
        content={confirmData}
        actionBtn={{
          name: "Confirm",
          action: onSubmit,
        }}
      />
      <CustomModal
        isShowing={isShowingDateErrorModal}
        toggle={toggleDateErrorModal}
        header="Error"
        content={
          <div>
            <span>Sorry, your quote date is a day in the past.</span>
          </div>
        }
        hiddenCancelBtn={true}
        actionBtn={{
          name: "Ok",
          action: onSubmit,
        }}
      />
      <ErrorModal
        isShowing={isShowingErrorModal}
        toggle={toggleErrorModal}
        errorData={errorData}
      />
      <CustomModal
        isShowing={isShowingApplyAgainodal}
        toggle={toggleApplyAgainModal}
        header="Please re-confirm your policy information"
        modalClassName="re-confirm-popup"
        actionBtn={{
          name: "Confirm",
          action: onApplyAgain,
        }}
        size="sm"
        hiddenCancelBtn="true"
        content={
          <table id="wrap" className="imf" width="100%">
            <tbody>
              <tr className="th">
                <td id="options_td">
                  <div style={{ marginBottom: "10px" }}>
                    Start Date <br />
                    <DatePicker
                      dateFormat={DATE_PICKER_FORMAT_DATE}
                      selected={new Date(data.startDate)}
                      onChange={(date) => {
                        handleChangeCommonQuoteData("startDate", date);
                        if (date > new Date(data.endDate)) {
                          handleChangeCommonQuoteData(
                            "endDate",
                            new Date(moment(date).add(1, "M"))
                          );
                        }
                      }}
                      minDate={new Date(moment(new Date()))}
                      maxDate={new Date(moment(new Date()).add(5, "y"))}
                      customInput={<CustomDateInput ref={startDateInputRef} />}
                      name={"startDate"}
                    />
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    End Date <br />
                    <DatePicker
                      dateFormat={DATE_PICKER_FORMAT_DATE}
                      selected={new Date(data.endDate)}
                      onChange={(date) =>
                        handleChangeCommonQuoteData("endDate", date)
                      }
                      minDate={
                        new Date(moment(new Date(data.startDate)).add(1, "d"))
                      }
                      maxDate={
                        new Date(moment(new Date(data.startDate)).add(8, "y"))
                      }
                      customInput={<CustomDateInput ref={endDateInputRef} />}
                      name={"endDate"}
                    />
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    No of Adults <br />
                    <select
                      name="no_of_adults"
                      id="no_of_adults"
                      className="no_of_adults"
                      value={data.numberOfAdults}
                      onChange={(value) =>
                        handleChangeCommonQuoteData("numberOfAdults", value)
                      }
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    No of children <br />
                    <select
                      name="no_of_children"
                      id="no_of_children"
                      className="no_of_children"
                      value={data.numberOfChildren}
                      onChange={(value) =>
                        handleChangeCommonQuoteData("numberOfChildren", value)
                      }
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        }
      />
    </div>
  );
};

export default ApplyAQuote;
