import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../defaultCss/styles.css";
import "../../defaultCss/css-agent-plugin.css";
import { fetchProviderRequest } from "../../reducers/reducer-get-quote";
import {
  API_FORMAT_DATE,
  API_URL,
  PROVIDER_ID,
  PAGE_URL,
} from "../../constants/constant";
import { Collapse } from "../Collapse";
import PayPalCheckout from "../PaypalCheckout";
import BankTransferCheckout from "../BankTransferCheckout";
import PaymentLinkAtBank from "../PaymentLinkAtBank";
import i18n from "i18n-iso-countries";
import dropin from "braintree-web-drop-in";
import useModal from "../../hooks/useModal";
import PaymentSuccessModal from "../PaymentSuccessModal";
import PaymentErrorModal from "../PaymentErrorModal";
import useApiServices from "../../hooks/useApiServices";
import PaymentStepsBreadcrumb from "../PaymentStepsBreadcrumb";
import { PaymentStepsBreadcrumbItems } from "../../enums/enum";
import PolicySummary from "../PolicySummary";
import CustomModal from "../CustomModal";
import { backToTransaction } from "../../reducers/reducer-apply-quote";
import Spinner from "../Spinner";
import QRCode from "react-qr-code";
import { navigateToGetAQuote } from "../../utils/utils";

const PaymentAQuote = () => {
  const navigate = useNavigate();

  const { providerList } = useSelector((store) => store.getAQuoteReducer);

  const { isShowing: isShowingSuccessModal, toggle: toggleSuccessModal } =
    useModal();

  const { isShowing: isShowingErrorModal, toggle: toggleErrorModal } =
    useModal();

  const {
    isShowing: isShowingPaymentErrorModal,
    toggle: togglePaymentErrorModal,
  } = useModal();

  const {
    isShowing: isShowingNotificationModal,
    toggle: toggleNotificationModal,
  } = useModal();

  const {
    visaMastercardCheckout,
    paypalCheckout,
    invoiceNumber,
    bankTransfers,
    nepalBankTransfers,
    data,
    quoteReference,
    enablePayment: {
      enableBankTransferPayment,
      enableFlywirePayment,
      enablePayAtBankPayment,
      enablePaypalPayment,
      enableVisaMastercardPayment,
      enableAlipayPayment,
      enableWechatPayment,
    },
    alipayCheckout,
    wechatCheckout,
    invalidDate,
  } = useSelector((store) => store.applyQuoteReducer);

  const refForm = useRef();
  const dispatch = useDispatch();
  const { postService } = useApiServices();

  const [expanedIndex, setExpandedIndex] = useState(-1);
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const searchParams = useLocation().search;
  const invoiceNumberParam = new URLSearchParams(searchParams).get(
    "invoiceNumber",
  );

  useEffect(() => {
    if (invalidDate) {
      toggleErrorModal();
    }
  }, [invalidDate]);

  useEffect(() => {
    toggleNotificationModal();
  }, []);

  useEffect(() => {
    if (!data.providerId) {
      if (invoiceNumberParam != null && invoiceNumberParam !== "") {
        setIsProcessing(true);
        dispatch(
          backToTransaction({
            data: invoiceNumberParam,
            callbackSuccess: () => {
              setIsProcessing(false);
            },
            callbackError: () => {
              setIsProcessing(false);
            },
          }),
        );
      } else {
        navigateToGetAQuote(navigate, data.agentCode, data.educationAgent);
      }
    } else {
      const scriptFlywire = document.createElement("script");
      scriptFlywire.src = "https://checkout.flywire.com/flywire-payment.js";
      scriptFlywire.async = true;
      document.body.appendChild(scriptFlywire);
      getProvider();
      if (data.providerId === PROVIDER_ID.NIB) {
        const initializeBraintree = () =>
          dropin.create(
            {
              // insert your tokenization key or client token here
              authorization: visaMastercardCheckout.token,
              container: "#braintree-drop-in-div",
              card: {
                cardholderName: true,
              },
            },
            function (error, instance) {
              if (error) console.error(error);
              else setBraintreeInstance(instance);
            },
          );

        if (braintreeInstance) {
          braintreeInstance.teardown().then(() => {
            initializeBraintree();
          });
        } else {
          initializeBraintree();
        }
      }
      return () => {
        document.body.removeChild(scriptFlywire);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data]);

  // This hook is listening an event that came from the Iframe
  useEffect(() => {
    const handler = (ev) => {
      if (typeof ev.data !== "object") return;

      if (data.providerId === PROVIDER_ID.ALLIANZ && quoteReference !== "") {
        if (ev.origin === "https://payment-page.emagin.eu" && ev.data != null) {
          if (ev.data.loadingStatus === "READY") {
            ev.source.postMessage("ack", ev.origin);
          }
          if (ev.data.paymentResponse != null) {
            if (ev.data.paymentResponse?.status === "Success") {
              setIsProcessingPayment(true);
              setTimeout(() => {
                postService(API_URL.TRANS_ALLIANZ_PAYMENT, {
                  quoteReference: quoteReference,
                })
                  .then(() => {
                    setIsProcessingPayment(false);
                    toggleSuccessModal();
                  })
                  .catch(() => {
                    setIsProcessingPayment(false);
                    togglePaymentErrorModal();
                  });
              }, 30000);
            } else {
              togglePaymentErrorModal();
            }
          }
        }
      }
    };

    window.addEventListener("message", handler);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", handler);
  }, [data.providerId, quoteReference]);

  //get latest price + logoImg
  const getProvider = useCallback(() => {
    switch (data.providerId) {
      case PROVIDER_ID.AHM:
      case PROVIDER_ID.MEDIBANK:
        refForm.current?.submit();
        break;
      default:
        break;
    }

    let request = {
      quoteCommonData: {
        startDate: data.startDate.format(API_FORMAT_DATE),
        endDate: data.endDate.format(API_FORMAT_DATE),
        adultsNum: data.numberOfAdults,
        childrensNum: data.numberOfChildren,
      },
      providerId: data.providerId,
      agent: data.agentCode,
    };
    dispatch(fetchProviderRequest(request));
  }, [
    data.agentCode,
    data.endDate,
    data.numberOfAdults,
    data.numberOfChildren,
    data.providerId,
    data.startDate,
    dispatch,
  ]);

  const handleExpand = (index) => {
    setExpandedIndex((prev) => (prev === index ? -1 : index));
  };

  const nibVisaMastercardCheckout = () => {
    if (braintreeInstance) {
      braintreeInstance.requestPaymentMethod((error, payload) => {
        if (error) {
          togglePaymentErrorModal();
        } else {
          setIsProcessingPayment(true);
          postService(API_URL.TRANS_NIB_VISA_MASTERCARD_CHECKOUT, {
            nonce: payload.nonce,
            invoiceNumber: invoiceNumber,
          })
            .then(() => {
              setIsProcessingPayment(false);
              toggleSuccessModal();
            })
            .catch(() => {
              setIsProcessingPayment(false);
              togglePaymentErrorModal();
            });
        }
      });
    }
  };

  const initFlywireCheckout = () => {
    const provider = providerList.find(
      (item) => item.providerId === data.providerId,
    );

    const countryCode = i18n.alpha3ToAlpha2(data.nationality);
    const config = {
      env: "prod",
      recipientCode: "ALN",
      amount: provider.price,
      nonce: invoiceNumber,
      // Recommended (not required) validation error handler
      onInvalidInput: function (errors) {
        errors.forEach(function (error) {
          alert(error.msg);
        });
      },
      // The callback function that is called once the payment is complete
      onCancel: function () {
        alert("Flywire checkout cancelled.");
      },
      requestPayerInfo: true,
      requestRecipientInfo: false,
      skipCompletedSteps: true,
      // Pass in complete payer information
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      country: countryCode,
      phone: data.phone,
      address: data.streetAndress,
      city: data.city,
      zip: data.postCode,
      // Pass in complete custom field information
      recipientFields: {
        policy_invoice_number: invoiceNumber,
        policy_provider: provider.providerName,
        policy_start_date: data.startDate,
        policy_end_date: data.endDate,
        policy_number_of_adults: data.numberOfAdults,
        policy_number_of_children: data.numberOfChildren,
      },
      readonlyFields: [
        "policy_invoice_number",
        "policy_provider",
        "policy_start_date",
        "policy_end_date",
        "policy_number_of_adults",
        "policy_number_of_children",
      ],
      // Supply a nonce to be included in the signature generation
      // The callback function that is called once the payment is complete
      onCompleteCallback: function (args) {
        toggleSuccessModal();
      },

      // Include these values if you intend to process Flywire server-to-server notifications (see documentation)
      callbackId: invoiceNumber,
      callbackUrl: `${API_URL.BASE}${API_URL.TRANS_WEBHOOK_FLYWIRE}`,
      callbackVersion: "2",
    };

    var modal = window.FlywirePayment.initiate(config);
    modal.render();
  };

  const initAlipayCheckout = () => {
    window.open(alipayCheckout.url, "_blank").focus();
  };

  const endSession = () => {
    setIsProcessing(true);
    postService(API_URL.EXPIRE_TRANSACTION, {
      invoiceNumber: invoiceNumber,
    })
      .then(() => {
        setIsProcessing(false);
        backToQuote();
      })
      .catch(() => setIsProcessing(false));
  };

  const backToQuote = () => {
    navigateToGetAQuote(navigate, data.agentCode, data.educationAgent);
  };

  const bupaVisaMastercardCheckout = () => {
    window.open(visaMastercardCheckout.url, "_blank").focus();
  };

  return (
    <div className="page">
      <div id="template-oshc" className="uc">
        <div id="service-suport" className="spec">
          <h3 className="section-title">Register with our services</h3>
          <div id="wrap" className="template-oshc">
            <div className="fix-width">
              <PaymentStepsBreadcrumb
                activeField={PaymentStepsBreadcrumbItems.PaymentAQuote}
              />
              <div className="main-apply">
                <PolicySummary
                  showEndSessionBtn={
                    (data.providerId === PROVIDER_ID.AHM ||
                      data.providerId === PROVIDER_ID.MEDIBANK) &&
                    !isExpired
                  }
                  data={data}
                  endSession={endSession}
                />
                {!invalidDate && (
                  <>
                    <div style={{ marginTop: "30px" }} className="top-content">
                      <p>
                        Please choose your preferred payment method, no matter
                        what country you are from:
                      </p>
                    </div>
                    <div className="wrap-payment">
                      <h3 className="title-pay-main">Payment methods</h3>
                      <div className="payment-cash">
                        {enableVisaMastercardPayment && (
                          <Collapse
                            title="Visa & MasterCard"
                            isExpanded={expanedIndex === 0}
                            onClick={() => handleExpand(0)}
                          >
                            {(data.providerId === PROVIDER_ID.AHM ||
                              data.providerId === PROVIDER_ID.MEDIBANK) && (
                              <div className="ahm-visa-mastercard-container">
                                <div className="note">
                                  <b>Note: </b>After payment successful.
                                  <div>
                                    Step 1: You please click "Send" button to
                                    send the receipt email to
                                    "info@annalink.com".
                                  </div>
                                  <div>
                                    Step 2: If you need get the receipt, Please
                                    input your email and click "Send" button
                                    again.
                                  </div>
                                </div>
                                <form
                                  target="visaMastercardIframe"
                                  action="https://quickweb.westpac.com.au/OnlinePaymentServlet"
                                  method="post"
                                  ref={refForm}
                                >
                                  <input
                                    type="hidden"
                                    name="cd_community"
                                    value="AHMG"
                                  />
                                  <input
                                    type="hidden"
                                    name="cd_supplier_business"
                                    value={
                                      data.providerId === PROVIDER_ID.AHM
                                        ? "B2B"
                                        : "B2BMa"
                                    }
                                  />
                                  <input
                                    type="hidden"
                                    name="cd_currency"
                                    value="AUD"
                                  />
                                  <input
                                    type="hidden"
                                    name="token"
                                    value={visaMastercardCheckout.token}
                                  />
                                </form>
                                <iframe
                                  title="visaMastercardIframe"
                                  style={{ width: "100%", height: "500px" }}
                                  name="visaMastercardIframe"
                                ></iframe>
                              </div>
                            )}
                            {data.providerId === PROVIDER_ID.BUPA && (
                              <div className="bupa-visamastercard-container">
                                <div className="note">
                                  <b>Note: </b>After payment successful.
                                  <div>
                                    Step 1: You please click "Send" button to
                                    send the receipt email to
                                    "info@annalink.com".
                                  </div>
                                  <div>
                                    Step 2: If you need get the receipt, Please
                                    input your email and click "Send" button
                                    again.
                                  </div>
                                </div>

                                <input
                                  type="button"
                                  className="nib-dropin-btn"
                                  name="checkout"
                                  value="Checkout"
                                  style={{ marginTop: "10px" }}
                                  onClick={bupaVisaMastercardCheckout}
                                />
                              </div>
                            )}
                            {data.providerId === PROVIDER_ID.ALLIANZ && (
                              <iframe
                                title="visaMastercardAllianzIframe"
                                style={{ width: "100%", height: "500px" }}
                                name="visaMastercardAllianzIframe"
                                src={`${visaMastercardCheckout.url}?requireAck=true`}
                              ></iframe>
                            )}
                            {data.providerId === PROVIDER_ID.NIB && (
                              <div className="dropin-wrapper">
                                <div
                                  className="nib-dropin-form"
                                  id="braintree-drop-in-div"
                                ></div>
                                <input
                                  type="button"
                                  className="nib-dropin-btn"
                                  name="checkout"
                                  value="Checkout"
                                  style={{ marginTop: "10px" }}
                                  onClick={nibVisaMastercardCheckout}
                                />
                              </div>
                            )}
                          </Collapse>
                        )}
                        {enablePayAtBankPayment && (
                          <Collapse
                            title="Pay link at Nepal bank"
                            isExpanded={expanedIndex === 1}
                            onClick={() => handleExpand(1)}
                          >
                            <PaymentLinkAtBank
                              bankTransfers={nepalBankTransfers}
                              invoiceNumber={invoiceNumber}
                            />
                          </Collapse>
                        )}
                        {enableBankTransferPayment && (
                          <Collapse
                            title="Pay by Bank Transfer"
                            isExpanded={expanedIndex === 2}
                            onClick={() => handleExpand(2)}
                          >
                            <BankTransferCheckout
                              bankTransfers={bankTransfers}
                              invoiceNumber={invoiceNumber}
                              quoteReference={quoteReference}
                              nationality={data?.nationality}
                            />
                          </Collapse>
                        )}
                        {enablePaypalPayment && (
                          <Collapse
                            title="Pay by PayPal"
                            isExpanded={expanedIndex === 3}
                            onClick={() => handleExpand(3)}
                          >
                            <PayPalCheckout
                              paypalCheckout={paypalCheckout}
                            ></PayPalCheckout>
                          </Collapse>
                        )}
                        {enableFlywirePayment && (
                          <Collapse
                            title="Pay by Flywire"
                            isExpanded={expanedIndex === 4}
                            onClick={() => handleExpand(4)}
                          >
                            <div className="flywire-container">
                              <button
                                id="pay_flywire"
                                className="flywire-btn"
                                onClick={initFlywireCheckout}
                              ></button>
                            </div>
                          </Collapse>
                        )}
                        {enableAlipayPayment && (
                          <Collapse
                            title="Pay by Alipay"
                            isExpanded={expanedIndex === 5}
                            onClick={() => handleExpand(5)}
                          >
                            <div className="alipay-container">
                              <div className="alipay-container-notes">
                                <span>
                                  Please complete the payment within 30 minutes;
                                  otherwise, the QR code will expire.
                                </span>
                                <span>
                                  If you are unable to scan the QR code, please
                                  close this tab and click the payment link
                                  provided in the invoice once again.
                                </span>
                              </div>
                              <button
                                id="pay_alipay"
                                className="alipay-btn"
                                onClick={initAlipayCheckout}
                              ></button>
                            </div>
                          </Collapse>
                        )}
                        {enableWechatPayment && (
                          <Collapse
                            title="Pay by Wechat"
                            isExpanded={expanedIndex === 6}
                            onClick={() => handleExpand(6)}
                          >
                            <div className="wechat-container">
                              <div className="wechat-container-notes">
                                <span>
                                  Please use Wechat app and scan QR code to make
                                  payment.
                                </span>
                                <span>
                                  Please complete the payment within 30 minutes;
                                  otherwise, the QR code will expire.
                                </span>
                                <span>
                                  If you are unable to scan the QR code, please
                                  close this tab and click the payment link
                                  provided in the invoice once again.
                                </span>
                              </div>
                              <QRCode size={128} value={wechatCheckout.url} />
                            </div>
                          </Collapse>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="cover-spin" style={{ display: !false ? "none" : "block" }}>
        <div className="spin-message">
          Please wait, we are processing your payment.
        </div>
      </div>
      <Spinner
        text="Please wait, we are processing your invoice."
        isShowing={isProcessing}
      />
      <Spinner
        text="Please wait, we are processing your payment."
        warningText="Please don't close this tab !"
        isShowing={isProcessingPayment}
      />
      <PaymentSuccessModal
        isShowing={isShowingSuccessModal}
        toggle={toggleSuccessModal}
      />
      <PaymentErrorModal
        isShowing={isShowingPaymentErrorModal}
        toggle={togglePaymentErrorModal}
      />
      <CustomModal
        isShowing={isShowingErrorModal}
        toggle={toggleErrorModal}
        header="Error"
        content={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Sorry, your quote date is invalid.</span>
            <span>Please contact us at info@oshcstudents.com for support!</span>
          </div>
        }
      />
      <CustomModal
        isShowing={isShowingNotificationModal}
        toggle={toggleNotificationModal}
        size="lg"
        header="Notification"
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              padding: "0 20px",
            }}
          >
            <ul>
              <li>
                If you make a payment successfully, we will send a confirmation
                of payment and a certificate of insurance to your email shortly.{" "}
              </li>
              {/* <li>
                With AHM/Medibank provider, if you want to create new invoice
                please click "End Payment Session" button. You also receive a
                guideline via your email.
              </li> */}
              <li>
                We do not accept claims in case of back dates.
                <br></br>
                <span>
                  For example, please DO NOT PAY an invoice with the start date
                  of policy before the current date
                </span>
              </li>
            </ul>
          </div>
        }
      />
    </div>
  );
};

export default PaymentAQuote;
