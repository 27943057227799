import React, { useCallback, useState } from "react";
import useModal from "../hooks/useModal";
import CustomModal from "./CustomModal";
import "./PaymentLinkAtBank.css";
import useApiServices from "../hooks/useApiServices";
import { API_URL } from "../constants/constant";

const PaymentLinkAtBank = (props) => {
  const { bankTransfers, invoiceNumber } = props;
  const { isShowing, toggle } = useModal();
  const [bankSelected, setBankSelected] = useState(bankTransfers[0]);
  const { postService } = useApiServices();
  const [branchSelected, setbranchSelected] = useState();
  const [bankBranchOptions, setbankBranchOptions] = useState(bankTransfers[0].bankBranchTransfers);

  const onBankClick = useCallback((bank) => {
    setBankSelected(bank);
    setbranchSelected("");
    setbankBranchOptions(bank.bankBranchTransfers);
  }, []);

  const onBranchChange = useCallback((branch) => {
    setbranchSelected(branch);
  }, []);
  const onSubmit = useCallback(() => {
    postService(API_URL.TRANS_NEPAL_PAYMENT, {
      bankName: bankSelected.name,
      bankBranchName: branchSelected,
      invoiceNumber: invoiceNumber,
    }).then(() => {
      toggle();
    });
  }, [bankSelected.name, invoiceNumber, branchSelected, postService, toggle]);

  return (
    <>
      <div className="payment-link-at-bank">
        <div>
          <b>Note:</b> After choose suitable bank and click submit, a payment
          link will be sent to the bank of your choice. Bring the invoice
          attached to the email to the bank to complete the insurance purchase.
        </div>
        <p>Please choose one suitable bank to make a payment</p>
        <div className="banks">
          {bankTransfers.map((item) => (
            <>
              <button
                key={item.name}
                className={`bankTransfer-button ${item.name === bankSelected?.name &&
                  "bankTransfer-button-selected"
                  }`}
                onClick={() => onBankClick(item)}
              >
                <div className="radioButton">
                  <input
                    type="radio"
                    checked={item.name === bankSelected?.name}
                    id={item.name}
                    name="radio-bank"
                  />
                  <label for={item.name}>{item.name}</label>
                </div>
                <img className="bank-logo" alt={item.name} src={item.logo} />
              </button>
            </>
          ))}
        </div>
        <div className="row justify-content-center mb-3" hidden={!bankSelected}>
          <div className="col-3">
            <label for="bank-branch" className="w-100 text-start m-0 p-0">Select a bank branch</label>
            <select className="form-select" value={branchSelected} onChange={(e) => onBranchChange(e.target.value)}>
              <option value="">Select a bank branch</option>
              {bankBranchOptions?.map((bankBranchOption) => (
                <option key={JSON.stringify(bankBranchOption)} value={bankBranchOption.name}>
                  {bankBranchOption.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <input
          type="button"
          className={`submit-btn`}
          name="checkout"
          value="Submit"
          onClick={onSubmit}
          disabled={!branchSelected}
        />
      </div>

      <CustomModal
        isShowing={isShowing}
        toggle={toggle}
        header="Thank you for submitting your payment"
        content={
          <div className="successModal">
            A payment link will be sent to the bank of your choice. Please bring
            the invoice attached to the email to the bank to complete the
            insurance purchase.
            <br />
            If you need supports, please contact our team at
            info@oshcstudents.com
          </div>
        }
      />
    </>
  );
};

export default PaymentLinkAtBank;
